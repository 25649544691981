import Experience from '../Experience.js'
import Helpers from '../Utils/Helpers.js'
import Environment from './Environment.js'
import HospitalRoom from './HospitalRoom.js'
import EventEmitter from '../Utils/EventEmitter.js'

export default class World extends EventEmitter {
    constructor() {
        super()

        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera.instance

        // Wait for resources to be loaded
        this.resources.on('ready', () => {
            // Setup
            this.environment = new Environment()
            this.helpers = new Helpers()
            this.hospitalRoom = new HospitalRoom()

            // When all resources have been loaded, emit and event
            // to start an animation to introduce to the scene
            this.trigger("worldready")
        })
    }

    /**
     * @description Update any animation.
     */
    update() {
        // Only update if the instance exists
        if (this.hospitalRoom) {
            this.hospitalRoom.update()
        }
    }
}