import "./style.css"
import Experience from './Experience/Experience.js'

// // Set the active menu text when in appropiate HTML section
// const sections = document.querySelectorAll(".section-link");
// const navLi = document.querySelectorAll(".links")

// window.addEventListener("scroll", () => {
//     let current = ""
//     sections.forEach((section) => {
//         const sectionTop = section.offsetTop
//         const sectionHeight = section.clientHeight
//         if (scrollY >= sectionTop - sectionHeight / 3) {
//             current = section.getAttribute("id")
//         }
//     })

//     navLi.forEach((li) => {
//         li.classList.remove("current-section")
//         if (li.classList.contains(current)) {
//             li.classList.add("current-section")
//         }
//     })
// })


// uses HTML5 history API to manipulate the location bar
history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search)

// For scroll to go to top when the page is refreshed
window.onbeforeunload = function () {
    // first: get the full url
    var hash_url = window.location.href

    // second: simply do a split
    // can't go wrong here, because url's that show content are always in correct format
    hash_url = hash_url.split('#')
    if (hash_url[1] != "contact") {
        var clean_url = hash_url[0]

        alert(clean_url)

        // Scroll to top
        window.scrollTo(0, 0);
    }
}

window.onload = function () {
    document.body.scrollTop = document.documentElement.scrollTop = 0
}


const experience = new Experience(document.querySelector('canvas.webgl'))




// ----------------------------------------------
// ----------------------------------------------
// ----------------------------------------------
// Animate the sections with text that are hidden by default
// Observer for the elements with "hidden" CSS class
const observerHidden = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
        entry.target.classList.toggle('show', entry.isIntersecting)
    })
}, {
    rootMargin: '-35% 0px -15% 0px'
})

const hiddenSectionTitles = document.querySelectorAll(".hidden")
hiddenSectionTitles.forEach(t => observerHidden.observe(t))


// ----------------------------------------------
// ----------------------------------------------
// ----------------------------------------------
