import * as THREE from 'three'
import Experience from './Experience.js'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// import { FlyControls } from 'three/examples/jsm/controls/FlyControls.js'

export default class Camera {
    constructor() {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.debug = this.experience.debug
        this.time = this.experience.time

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("Camera")

        }

        this.setInstance()
        // this.setControls()
        // this.setFirstPersonControls()
    }

    /**
     * @description Create a Three.js `PerspectiveCamera` with the aspect ratio of the
     * `Experience` intance. It also positions it and adds it to the scene.
     */
    setInstance() {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 100)

        // Set camera initial position (viewing from the door window)
        this.instance.position.set(6.6, 2.02, 0.008)
        this.instance.rotation.set(-Math.PI / 2, 1.466, Math.PI / 2)

        this.scene.add(this.instance)

        // Debug
        if (this.debug.active) {
            // Create camera position subfolder
            this.cameraFolderPosition = this.debugFolder.addFolder("Position")

            this.cameraFolderPosition
                .add(this.instance.position, "x")
                .name("X")
                .min(0)
                .max(20)
                .step(0.01)

            this.cameraFolderPosition
                .add(this.instance.position, "y")
                .name("Y")
                .min(0)
                .max(20)
                .step(0.01)

            this.cameraFolderPosition
                .add(this.instance.position, "z")
                .name("Z")
                .min(0)
                .max(20)
                .step(0.01)


            // Create camera position subfolder
            this.cameraFolderRotation = this.debugFolder.addFolder("Rotation")

            this.cameraFolderRotation
                .add(this.instance.rotation, "x")
                .name("X")
                .min(0)
                .max(20)
                .step(Math.PI * 0.01 * 2)

            this.cameraFolderRotation
                .add(this.instance.rotation, "y")
                .name("Y")
                .min(0)
                .max(20)
                .step(Math.PI * 0.01 * 2)

            this.cameraFolderRotation
                .add(this.instance.rotation, "z")
                .name("Z")
                .min(0)
                .max(20)
                .step(Math.PI * 0.01 * 2)
        }

    }

    /**
     * @description Create a Three.js `OrbitControls` using the `PerspectiveCamera` created
     * in the `setInstance()` method and the canvas from the `Experience` instance.
     * It also enables damping to smooth the movements.
     */
    setControls() {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
        // this.controls.enableZoom = false

        // Debug
        if (this.debug.active) {
            // Create camera position subfolder
            this.cameraFolderOrbitControls = this.debugFolder.addFolder("Orbit Controls")
            this.cameraFolderOrbitControls
                .add(this.controls, "enableZoom")
        }
    }

    /**
     * @description Creates a first person control camera to determine the position in the scene 
     */
    setFirstPersonControls() {
        this.firstPersonControls = new FlyControls(this.instance, this.canvas)
        this.firstPersonControls.movementSpeed = 2
        this.firstPersonControls.rollSpeed = 0.25
        this.firstPersonControls.dragToLook = true
        // this.firstPersonControls.lookSpeed = 0.08
    }

    /**
     * @description Resizes the aspect ratio of the camera and updates the projection
     * matrix. This should be call when a window resize event has been encountered.
     */
    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    /**
     * @description Updates the `OrbitControls` when a tick event has been encountered
     * during the animation of the scene.
     */
    update() {
        // this.controls.update()
        // this.firstPersonControls.update(this.time.delta * 0.001)
    }
}