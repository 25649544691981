import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter {
    constructor() {
        super()

        // Setup
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)
        this.menuEnabled = false

        if (this.width < 1072) {
            this.device = "mobile";
        } else {
            this.device = "desktop";
        }


        // Resize event
        window.addEventListener('resize', () => {
            this.width = window.innerWidth
            this.height = window.innerHeight
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)

            if (this.device === "mobile") {
                document.querySelector(".mobile-nav-toggle").style.display = "block"
            }

            this.trigger('resize')

            // Check the device regarding the size of the screen
            // But only do the trigger when the switch is performed
            // from one device to the other, not only when the screen
            // is resized
            if (this.width < 1072 && this.device !== "mobile") {
                this.device = "mobile"
                this.trigger("switchdevice", this.device)
            } else if (this.width >= 1072 && this.device !== "desktop") {
                this.device = "desktop"
                this.trigger("switchdevice", this.device)
            }
        })
    }
}