import EventEmitter from './Utils/EventEmitter.js'
import Experience from './Experience.js'
import * as THREE from 'three'
import gsap from 'gsap'
import SplitType from "split-type"

export default class Preloader extends EventEmitter {
    constructor() {
        super()

        // Retrieve the elements from the experience
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.camera = this.experience.camera

        this.world = this.experience.world

        // Save in which device the screen is being visualized
        this.device = this.sizes.device
        this.sizes.on("switchdevice", (device) => {
            this.device = device
        })

        // Save a flag to know if the menu can be activated
        this.menuEnabled = this.sizes.menuEnabled

        // Listen to the event that indicates that the resources have been
        // loaded to start the animation to the scene
        this.world.on("worldready", () => {
            this.setAssets()
            this.playIntro()
        })
    }

    /**
     * @description Sets the assets in the room scene model.
     */
    setAssets() {
        this.room = this.experience.world.hospitalRoom.actualRoom
        this.roomElements = this.experience.world.hospitalRoom.roomChildren
    }


    /**
     * @description Sets the initial camera of the preloader to move depending
     * on the device being used.
     */
    firstIntro() {

        // This is done to ensure that the next animations can only run
        // when first intro has finished!
        // (it uses asynchronous programming!)
        return new Promise((resolve) => {


            this.backPlane = new THREE.Mesh(
                new THREE.PlaneGeometry(2, 2, 2),
                new THREE.MeshBasicMaterial({
                    color: "black",
                    side: THREE.DoubleSide
                })
            )

            this.backPlane.rotateY(Math.PI / 2)
            this.backPlane.position.set(6, 2, 0)

            this.scene.add(this.backPlane)


            this.timeline = new gsap.timeline()

            // Add loader dots
            this.timeline
                .to(".preloader", {
                    opacity: 0,
                    delay: 1,
                    onComplete: () => {
                        document.querySelector(".preloader").classList.add("hidden-preloader")
                    }
                })
                // Position and move the camera loader
                .to(this.roomElements.brain_loader.scale, {
                    x: 1,
                    y: 1,
                    z: 1,
                    ease: "back.out(1.7)",
                    duration: 1,
                }, "introAnimation")
                .to(this.roomElements.brain_loader.position, {
                    y: 2.03,
                    ease: "power1.out",
                    duration: 0.5,
                }, "introAnimation")


            // Animate the text underneath the camera
            this.preloaderText = new SplitType(".preloader-text") // Split the text into div elemtns of "char" class

            document.querySelector(".preloader-text").style.opacity = 1

            // Animate header text with the name of the database
            this.timeline.to(this.preloaderText.chars, {
                opacity: 1,
                y: 0,
                ease: "back.out(1.7)",
                stagger: 0.025, // To ensure that each character is animated at certain time individually
                delay: 0.5,
            }, "introAnimation")

            // Start loading all elements in the scene 
            Object.values(this.roomElements).forEach((element) => {
                element.visible = true
            })

            // Add mouse scroll down animation
            this.timeline.to(".mouse-wrapper", {
                opacity: 1,
                ease: "power1.out",
                duration: 1,
                delay: 0.5,
                onComplete: resolve
            })

        })

    }

    /**
     * @description Moves the camera 3D model to where the camera
     * is looking at in the render.
     */
    secondIntro() {

        // This is done to ensure that the next animations can only run
        // when first intro has finished!
        // (it uses asynchronous programming!)
        return new Promise((resolve) => {


            this.secondTimeline = new gsap.timeline()

            // Perform both animations in the desktop and mobile versions
            // Remove preloader text
            this.secondTimeline
                // Remove mouse scroll down animation
                .to(".mouse-wrapper", {
                    opacity: 0,
                    ease: "power1.out",
                    duration: 0.5,
                })
                // Animate the preloader text
                .to(this.preloaderText.chars, {
                    y: -115,
                    stagger: 0.0125, // To ensure that each character is animated at certain time individually
                    duration: 0.00125, // How much time takes for the animation to be done
                }, "beforeFadeEffect")
                // Hide text
                .to(this.preloaderText.chars, {
                    y: -115,
                    opacity: 0,
                    fontSize: 0,
                    stagger: 0.0125, // To ensure that each character is animated at certain time individually
                    duration: 0.00125, // How much time takes for the animation to be done
                }, "beforeFadeEffect")
                // Move camera to the center
                .to(this.roomElements.brain_loader.position, {
                    z: 0,
                    ease: "power1.out",
                    delay: 0.1,
                    duration: 0.25
                }, "beforeFadeEffect")
                // Make the camera point to us
                .to(this.roomElements.brain_loader.rotation, {
                    y: 2 * Math.PI,
                    ease: "power1.out",
                    delay: 0.1,
                    duration: 0.75
                }, "beforeFadeEffect")
                // Move the camera to where we are and ...
                .to(this.roomElements.brain_loader.position, {
                    z: this.camera.instance.position.z - 0.05,
                    duration: 0.75,
                }, "fadeEffect")
                .to(this.roomElements.brain_loader.rotation, {
                    x: 0,
                    z: 0,
                    duration: 0.75,
                }, "fadeEffect")
                // Move camera to the left
                .to(this.roomElements.brain_loader.position, {
                    z: 0.5,
                    ease: "power1.out",
                    duration: 0.75,
                }, "fadeCameraMove")
                // Overlay transition effects
                .to(".overlay-first", {
                    opacity: 1,
                    left: "-100%",
                    ease: "expo.inOut",
                    duration: 1,
                }, "fadeCameraMove")
                .to(".overlay-second", {
                    opacity: 1,
                    left: "-100%",
                    delay: -0.8,
                    ease: "expo.inOut",
                    duration: 1,
                }, "fadeCameraMove")
                .to(this.backPlane, {
                    visible: 0,
                    duration: 0.5,
                    ease: "power1.out",
                    onComplete: resolve,
                }, "fadeCameraMove")
        })

    }

    /**
     * @description Moves the camera 3D model to where the camera
     * is looking at in the render.
     */
    lastIntro() {

        // Change the style of the div so that the menu text is on top
        document.querySelector(".primary-navigation").style.removeProperty("margin_top")

        // Remove camera loader 3D model
        this.roomElements.brain_loader.visible = false

        // Move the camera to the outside of the room
        this.camera.instance.position.set(6.6, 2.02, 0.008)

    }

    /**
     * @description Checks if the user has scrolled down in the desktop
     * version. If so, remove any event listener waiting for the user before
     * starting the second intro animation.
     */
    onScroll(event) {
        if (event.deltaY > 0) {
            this.removeEventListeners()
            this.playSecondIntro()
        }
    }

    /**
     * @description Checks if the user has touched the screen
     * to register its value.
     */
    onTouch(event) {
        this.initialY = event.touches[0].clientY
    }

    /**
     * @description Checks if the user has swipped up in the mobile
     * version. If so, remove any event listener waiting for the user before
     * starting the second intro animation.
     */
    onTouchMove(event) {
        let currentY = event.touches[0].clientY
        let difference = this.initialY - currentY

        if (difference > 0) {
            this.removeEventListeners()
            this.playSecondIntro()
        }

        this.initialY = null
    }

    /**
     * @description Animates the navigation menu.
     */
    animateNavigationMenu() {
        // NAVIGATION MENU
        const primaryNav = document.querySelector(".primary-navigation")
        const navToggle = document.querySelector(".mobile-nav-toggle")
        const titleHeaderMenu = document.querySelector(".header-title")

        navToggle.addEventListener("click", () => {
            const visibility = primaryNav.getAttribute("data-visible")

            if (visibility === "false") {
                primaryNav.setAttribute("data-visible", true)
                navToggle.setAttribute("aria-expanded", true)
                titleHeaderMenu.setAttribute("data-visible", true)

            }
            else if (visibility === "true") {
                primaryNav.setAttribute("data-visible", false)
                navToggle.setAttribute("aria-expanded", false)
                titleHeaderMenu.setAttribute("data-visible", false)

            }
        })

        // MENU TEXT ANIMATION
        // Wait until the DOM content has been loaded
        this.on("enableMenu", () => {
            this.menuEnabled = true

            if (this.device === "mobile") {
                document.querySelector(".mobile-nav-toggle").style.display = "block"
            }

            const heroText = new SplitType(".header-title") // Split the text into div elemtns of "char" class

            document.querySelector(".header-title").style.opacity = 1

            // Animate header text with the name of the database
            gsap.to(heroText.chars, {
                opacity: 1,
                y: 0,
                stagger: 0.05, // To ensure that each character is animated at certain time individually
                delay: 0.2,
                duration: 0.1 // How much time takes for the animation to be done
            })

            // Animate hero description
            gsap.to(".header-description", {
                opacity: 1,
                delay: 1,
                duration: 1 // How much time takes for the animation to be done
            })

            // Animate navigation background
            gsap.to(".primary-navigation", {
                opacity: 1,
                delay: 1.25,
                duration: 0.5
            })

            // Animate navigation menu text
            gsap.to(".active", {
                opacity: 1,
                delay: 1.5,
                stagger: 0.1,
                duration: 0.5
            })
        })
    }

    /**
     * @description Removes all events that are being listened to while
     * waiting for the user to either scroll or touch its device screen.
     */
    removeEventListeners() {
        window.removeEventListener("wheel", this.scrollOnceEvent)
        window.removeEventListener("touchstart", this.touchStartEvent)
        window.removeEventListener("touchmove", this.touchMoveEvent)
    }

    /**
     * @description Plays intro animation of the scene when the
     * first animation has finished by using `async`.
     */
    async playIntro() {
        // Wait for the first intro to end before executing the 
        // next code!
        await this.firstIntro()

        // Create a pointer with the function for the event listener
        // this way we will be able to remove that function from being listened
        this.scrollOnceEvent = this.onScroll.bind(this)
        window.addEventListener("wheel", this.scrollOnceEvent)

        // Events for the mobile
        this.touchStartEvent = this.onTouch.bind(this)
        this.touchMoveEvent = this.onTouchMove.bind(this)
        window.addEventListener("touchstart", this.touchStartEvent)
        window.addEventListener("touchmove", this.touchMoveEvent)

    }

    /** 
     * @description Plays second animation of the scene when the
     * first animation has finished by using `async`.
     */
    async playSecondIntro() {
        await this.secondIntro()
        this.playLastIntro()
    }

    /** 
     * @description Plays last animation of the scene by
     * making all elements but the camera and plane visible.
     */
    playLastIntro() {
        this.lastIntro()
        this.animateNavigationMenu()
        this.trigger("enableControls")
        this.trigger("enableMenu")

        // Remove sunlight intensity (removing the visibility results in lag)
        this.experience.world.environment.sunLight.intensity = 0

        // Enable lerping to move the scene on mouse movement
        this.experience.world.hospitalRoom.onMouseMove()
    }


}