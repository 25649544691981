import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Helpers {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.debug = this.experience.debug


        // Debug
        if (this.debug.active) {
            this.axesHelper = new THREE.AxesHelper(5)
            this.scene.add(this.axesHelper)

            this.debugFolder = this.debug.ui.addFolder('Axes')

            // Add option to view and modify helper axes
            this.debugFolder.add(this.axesHelper, "visible").name("Enable")
        }

    }
}