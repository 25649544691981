export default [
    {
        name: 'hospitalRoom',
        type: 'gltfModel',
        path: 'models/HU12O_surgery_room_comp.glb'
    },

    // {
    //     name: 'leftScreen',
    //     type: 'videoTexture',
    //     path: 'textures/classification.mp4'
    // },

    // {
    //     name: 'rightScreen',
    //     type: 'videoTexture',
    //     path: 'textures/depth.mp4'
    // },
]