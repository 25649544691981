import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('Environment')
        }

        this.setLightSourceIllumination()
        this.setSunLight()
        this.setAmbientLight()
        this.setEnvironmentMap()
    }

    setLightSourceIllumination() {

        this.lightSurgical_1 = new THREE.SpotLight("#ffffff")
        this.lightSurgical_1.penumbra = 1.5
        this.lightSurgical_1.castShadow = true
        this.lightSurgical_1.shadow.camera.far = 15
        this.lightSurgical_1.shadow.mapSize.set(1024, 1024)
        this.lightSurgical_1.shadow.normalBias = 0.05
        this.lightSurgical_1.position.set(0.8, 2.3, -0.1)
        this.scene.add(this.lightSurgical_1)

        this.lightSurgical_2 = new THREE.SpotLight("#ffffff")
        this.lightSurgical_2.penumbra = 1.5
        this.lightSurgical_2.castShadow = true
        this.lightSurgical_2.shadow.camera.far = 15
        this.lightSurgical_2.shadow.mapSize.set(1024, 1024)
        this.lightSurgical_2.shadow.normalBias = 0.05
        this.lightSurgical_2.position.set(-0.8, 2.3, -0.1)
        this.scene.add(this.lightSurgical_2)

        // Debug
        if (this.debug.active) {
            // Create new subfolder
            this.lightSourceFolder = this.debugFolder.addFolder("Lighting")
            this.surgicalLight_1 = this.lightSourceFolder.addFolder("Surgical Light 1")

            this.surgicalLight_1
                .add(this.lightSurgical_1, 'angle')
                .name('angle')
                .min(0)
                .max(10)
                .step(0.001)

            this.surgicalLight_1
                .add(this.lightSurgical_1, 'penumbra')
                .name('penumbra')
                .min(0)
                .max(10)
                .step(0.001)

            this.surgicalLight_1
                .add(this.lightSurgical_1, 'decay')
                .name('decay')
                .min(0)
                .max(10)
                .step(0.001)

            this.surgicalLight_1
                .add(this.lightSurgical_1, 'distance')
                .name('distance')
                .min(0)
                .max(10)
                .step(0.001)

            this.surgicalLight_1
                .add(this.lightSurgical_1, 'intensity')
                .name('Intensity')
                .min(0)
                .max(10)
                .step(0.001)

            this.surgicalLight_1
                .add(this.lightSurgical_1.position, 'x')
                .name('X')
                .min(- 5)
                .max(5)
                .step(0.001)

            this.surgicalLight_1
                .add(this.lightSurgical_1.position, 'y')
                .name('Y')
                .min(- 5)
                .max(5)
                .step(0.001)

            this.surgicalLight_1
                .add(this.lightSurgical_1.position, 'z')
                .name('Z')
                .min(- 5)
                .max(5)
                .step(0.001)


            // Create a light helper
            this.lightSourceHelper = new THREE.SpotLightHelper(this.lightSurgical_1)
            this.scene.add(this.lightSourceHelper)

            this.surgicalLight_2 = this.lightSourceFolder.addFolder("Surgical Light 2")

            this.surgicalLight_2
                .add(this.lightSurgical_2, 'angle')
                .name('angle')
                .min(0)
                .max(10)
                .step(0.001)

            this.surgicalLight_2
                .add(this.lightSurgical_2, 'penumbra')
                .name('penumbra')
                .min(0)
                .max(10)
                .step(0.001)

            this.surgicalLight_2
                .add(this.lightSurgical_2, 'decay')
                .name('decay')
                .min(0)
                .max(10)
                .step(0.001)

            this.surgicalLight_2
                .add(this.lightSurgical_2, 'distance')
                .name('distance')
                .min(0)
                .max(10)
                .step(0.001)

            this.surgicalLight_2
                .add(this.lightSurgical_2, 'intensity')
                .name('Intensity')
                .min(0)
                .max(10)
                .step(0.001)

            this.surgicalLight_2
                .add(this.lightSurgical_2.position, 'x')
                .name('X')
                .min(- 5)
                .max(5)
                .step(0.001)

            this.surgicalLight_2
                .add(this.lightSurgical_2.position, 'y')
                .name('Y')
                .min(- 5)
                .max(5)
                .step(0.001)

            this.surgicalLight_2
                .add(this.lightSurgical_2.position, 'z')
                .name('Z')
                .min(- 5)
                .max(5)
                .step(0.001)


            // Create a light helper
            this.lightSourceHelper_2 = new THREE.SpotLightHelper(this.lightSurgical_2)
            this.scene.add(this.lightSourceHelper_2)
        }
    }

    setSunLight() {
        this.sunLight = new THREE.DirectionalLight('#ffffff', 1)
        this.sunLight.castShadow = true
        this.sunLight.shadow.camera.far = 15
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(1, 4, 2)
        this.scene.add(this.sunLight)

        // Debug
        if (this.debug.active) {
            // Create new subfolder
            this.sunLightFolder = this.debugFolder.addFolder("Sunlight")

            this.sunLightFolder
                .add(this.sunLight, 'intensity')
                .name('Intensity')
                .min(0)
                .max(10)
                .step(0.001)

            this.sunLightFolder
                .add(this.sunLight.position, 'x')
                .name('X')
                .min(- 5)
                .max(5)
                .step(0.001)

            this.sunLightFolder
                .add(this.sunLight.position, 'y')
                .name('Y')
                .min(- 5)
                .max(5)
                .step(0.001)

            this.sunLightFolder
                .add(this.sunLight.position, 'z')
                .name('Z')
                .min(- 5)
                .max(5)
                .step(0.001)

            // Create a light helper
            this.sunLightHelper = new THREE.DirectionalLightHelper(this.sunLight, 5)
            this.scene.add(this.sunLightHelper)
        }
    }

    setAmbientLight() {
        this.ambientLight = new THREE.AmbientLight("#ffffff", 1.0)
        this.scene.add(this.ambientLight)

        // Debug
        if (this.debug.active) {
            // Create new subfolder
            this.ambientLightFolder = this.debugFolder.addFolder("Ambient Light")

            this.ambientLightFolder
                .add(this.ambientLight, 'intensity')
                .name('Intensity')
                .min(0)
                .max(10)
                .step(0.001)
        }
    }

    setEnvironmentMap() {
        this.environmentMap = {}
        this.environmentMap.intensity = 0.4
        this.environmentMap.texture = this.resources.items.environmentMapTexture

        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () => {
            this.scene.traverse((child) => {
                if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }
        this.environmentMap.updateMaterials()

        // Debug
        if (this.debug.active) {
            this.debugFolder
                .add(this.environmentMap, 'intensity')
                .name('envMapIntensity')
                .min(0)
                .max(4)
                .step(0.001)
                .onChange(this.environmentMap.updateMaterials)
        }
    }
}