import * as THREE from 'three'

import Debug from './Utils/Debug.js'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'
import Resources from './Utils/Resources.js'
import Preloader from './Preloader.js'
import Controls from './Utils/Controls.js'


import sources from './sources.js'

// Needed for the singleton
let instance = null

export default class Experience {
    constructor(_canvas) {
        /** 
        * This is done to ensure that only 1 instance of Experience can be created.
        * When the first instance is created, the next time using "new Experience()"
        * will return the already created instance.
        * This is actually called a Singleton and its way cleaner than using a global
        * access variable or sending parameters to a class constructor.
        */
        if (instance) {
            return instance
        }
        instance = this

        // Global access
        window.experience = this

        // Options
        this.canvas = _canvas

        // Setup
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.resources = new Resources(sources)
        this.camera = new Camera()
        this.renderer = new Renderer()
        this.world = new World()
        this.preloader = new Preloader()

        // Listen to event indicating that the load has been done
        this.preloader.on("enableControls", () => {
            this.controls = new Controls()
        })

        // * Block arrow keys to scroll
        // window.addEventListener("keydown", function (e) {
        //     if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
        //         e.preventDefault()
        //     }
        // }, false)

        // Resize event
        this.sizes.on('resize', () => {
            this.resize()
        })

        // Time tick event
        this.time.on('tick', () => {
            this.update()
        })
    }

    /**
     * @description: Method to propagate the resize method on 
     * the camera and renderer instances. This will be called when
     * a window resize event has been encountered.
     */
    resize() {
        this.camera.resize()
        this.renderer.resize()
    }

    /**
     * @description: Method to propagate the update method on 
     * the camera, world and renderer instances. This will be called when
     * a frame animation needs to be performed after listening to 
     * a tick event.
     */
    update() {
        this.camera.update()
        this.world.update()
        this.renderer.update()
    }

    destroy() {
        // When destroying anything, first stop listening to the Time ans Sizes events
        this.sizes.off('resize')
        this.time.off('tick')

        // Traverse the whole scene
        this.scene.traverse((child) => {
            // Test if it's a mesh
            if (child instanceof THREE.Mesh) {
                child.geometry.dispose()

                // Loop through the material properties
                for (const key in child.material) {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if (value && typeof value.dispose === 'function') {
                        value.dispose()
                    }
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()

        if (this.debug.active)
            this.debug.ui.destroy()
    }
}