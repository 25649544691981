import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"
import EventEmitter from './EventEmitter.js'

export default class Resources extends EventEmitter {
    constructor(sources) {
        super()

        this.sources = sources

        this.items = {}
        this.toLoad = this.sources.length
        this.loaded = 0

        this.setLoaders()
        this.startLoading()
    }

    /**
     * @description Create Three.js loaders for GLTF models and textures.
     */
    setLoaders() {
        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader()
        this.loaders.dracoLoader = new DRACOLoader();
        this.loaders.dracoLoader.setDecoderPath("/draco/");
        this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader);
        this.loaders.textureLoader = new THREE.TextureLoader()
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
    }

    /**
     * @description Loads all resources from the passed file
     * with all resources information.
     */
    startLoading() {
        // Load each source
        for (const source of this.sources) {

            if (source.type === 'gltfModel') {
                this.loaders.gltfLoader.load(
                    // Load all resources in path
                    source.path,
                    // When loaded, pass the loaded resources
                    // as a file and call the `sourceLoaded` method.
                    (file) => {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if (source.type === "videoTexture") {
                this.video = {};
                this.videoTexture = {};

                this.video[source.name] = document.createElement("video");
                this.video[source.name].src = source.path
                this.video[source.name].muted = true
                this.video[source.name].playsInline = true
                this.video[source.name].autoplay = true
                this.video[source.name].loop = true
                this.video[source.name].play()

                this.videoTexture[source.name] = new THREE.VideoTexture(
                    this.video[source.name]
                );
                // this.videoTexture[source.name].flipY = false;
                this.videoTexture[source.name].minFilter = THREE.NearestFilter
                this.videoTexture[source.name].magFilter = THREE.NearestFilter
                this.videoTexture[source.name].generateMipmaps = false
                this.videoTexture[source.name].encoding = THREE.sRGBEncoding

                this.sourceLoaded(source, this.videoTexture[source.name])
            }
        }
    }

    /**
     * @description Store the source and the object created by the loaders
     * ("file") in the `items` instance property. It also increments the `loaded`
     * property to check if all resources have been loaded. Finally, trigger a 
     * "ready" event to indicate that the resources have been loaded.
     */
    sourceLoaded(source, file) {
        this.items[source.name] = file

        this.loaded++

        if (this.loaded === this.toLoad) {
            this.trigger('ready')
        }
    }
}