import * as THREE from 'three'
import GSAP from "gsap"
import Experience from '../Experience.js'

export default class HospitalRoom {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('Room')
        }

        // Resource
        this.actualRoom = this.resources.items.hospitalRoom

        // Object with all elements in the model scene
        this.roomChildren = {}

        // Add lerp movement when passing the mouse through the viewport
        this.lerpLateral = {
            current: 0,
            target: 0,
            ease: 0.1,
        }

        this.lerpAngle = {
            current: 0,
            target: 0,
            ease: 0.1,
        }

        this.setModel()
        this.setAnimation()
    }

    setModel() {
        this.model = this.actualRoom.scene
        this.scene.add(this.model)

        this.model.children.forEach((child) => {

            child.visible = false

            if (child.name === "Brain_loader") {
                child.visible = true
                child.scale.set(0, 0, 0)
                child.position.set(6.35, 2.02, 0.008)
                this.experience.camera.instance.position.set(6.6, 2.02, 0.008)
            }
            if (child instanceof THREE.Mesh) {
                child.castShadow = true
                child.receiveShadow = true
                child.material.envMapIntensity = 2.5
            }

            // Enable this if you want to aadd a video
            // if (child.name === "Monitor_left_screen") {
            //     child.material = new THREE.MeshBasicMaterial({
            //         map: this.resources.items.leftScreen
            //     })
            // }

            // if (child.name === "Monitor_right_screen") {
            //     child.material = new THREE.MeshBasicMaterial({
            //         map: this.resources.items.rightScreen
            //     })
            // }

            this.roomChildren[child.name.toLowerCase()] = child
        })

    }

    /**
     * @description Sets the desired animations included in the loaded Blender model.
     */
    setAnimation() {
        this.animationMixer = new THREE.AnimationMixer(this.actualRoom.scene);
        this.brainPulse = this.animationMixer.clipAction(this.actualRoom.animations[0]);
        this.brainPulse.play();
    }

    /**
     * @description Listens to any mouse movement so that the lerp (or interpolation) is
     * calculated in order to rotate the Hospital room scene.
     */
    onMouseMove() {
        window.addEventListener("mousemove", (e) => {
            // Compute a ratio between -1 and 1 regardless of the size of the screen
            this.rotationLateral =
                ((e.clientX - window.innerWidth / 2) * 2) / window.innerWidth;

            this.lerpLateral.target = this.rotationLateral * 0.025; // Multiply by a small factor to reduce the rotation

            // Do the same but to move upwards and downwards
            // this.rotationAngle = ((e.clientY - window.innerHeight / 2) * 2) / window.innerHeight;
            // this.lerpAngle.target = this.rotationAngle * 0.025
        });
    }

    update() {
        // Update the rotation of the room regarding the position of the mouse
        this.lerpLateral.current = GSAP.utils.interpolate(
            this.lerpLateral.current,
            this.lerpLateral.target,
            this.lerpLateral.ease
        );

        this.actualRoom.scene.rotation.y = this.lerpLateral.current

        this.lerpAngle.current = GSAP.utils.interpolate(
            this.lerpAngle.current,
            this.lerpAngle.target,
            this.lerpAngle.ease
        );

        this.actualRoom.scene.rotation.z = this.lerpAngle.current

        // Update the animations in the scene
        this.animationMixer.update(this.time.delta * 0.0009);
    }
}