import * as THREE from "three"
import Experience from "../Experience"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger.js"
import ASScroll from '@ashthornton/asscroll'

const cameraMovement = {
    // Pass room window
    initialMove:
    {
        desktop: {
            position: {
                x: 2.85,
                y: 2.02,
                z: 0.008
            }
        },
        mobile: {
            position: {
                x: 2.85,
                y: 2.02,
                z: 0.008
            }
        },

    },

    // Focus on the brain
    firstMove:
    {
        desktop: {
            position: {
                x: 1.16,
                y: 1.86,
                z: 0.008
            },
            rotation: {
                x: -2.41,
                y: 0.78,
                z: 2.72,
            }
        },

        mobile: {
            position: {
                x: 1.16,
                y: 1.86,
                z: 0.008
            },
            rotation: {
                x: -2.41,
                y: 0.78,
                z: 2.72,
            }
        }
    },

    // Focus on the cameras
    secondMove:
    {
        desktop: {
            position: {
                x: -0.4, y: 1.6264306752382998, z: 0.5402590146751826,
            },
            rotation: {
                x: 0.1639840750909612, y: -0.8138431516450426, z: 0.00018620455822798736
            }
        },

        mobile: {
            position: {
                x: -0.4, y: 1.6264306752382998, z: 0.5402590146751826,
            },
            rotation: {
                x: 0.1639840750909612, y: -0.8138431516450426, z: 0.00018620455822798736
            }
        }
    },

    // View the surgery room from the corner in the back
    thirdMove:
    {
        desktop: {
            position: {
                x: -2.5478266202228764, y: 2.9561643279679934, z: -2.4143505902177633
            },
            rotation: {
                x: -2.5791615016517486, y: -0.49488926763190966, z: -2.758275152229616
            }
        },

        mobile: {
            position: {
                x: -2.5478266202228764, y: 2.9561643279679934, z: -2.4143505902177633
            },
            rotation: {
                x: -2.5791615016517486, y: -0.49488926763190966, z: -2.758275152229616
            }
        }
    },

    // View the computer of the prototype
    fourthMove:
    {
        desktop: {
            position: {
                x: 0, y: 1.75, z: -2.6
            },
            rotation: {
                x: -Math.PI, y: 0, z: -Math.PI
            }
        },

        mobile: {
            position: {
                x: 0, y: 1.75, z: -2.6
            },
            rotation: {
                x: -Math.PI, y: 0, z: -Math.PI
            }
        }
    },

    // Get close to the computer screen of the prototype
    fifthMove:
    {
        desktop: {
            position: {
                x: 0, y: 1.75, z: -2.0
            },
        },

        mobile: {
            position: {
                x: 0, y: 1.75, z: -2.0
            },
        }
    },


}


export default class Controls {

    constructor() {
        // Retrieve the elements from the experience
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.camera = this.experience.camera

        this.hospitalRoom = this.experience.world.hospitalRoom.scene

        // Register the ScrollTrigger plugin to be used
        // for the scrolling animation
        gsap.registerPlugin(ScrollTrigger)

        // Define timelime for the animation
        this.timeline = new gsap.timeline()

        // To indicate if 
        this.animating = false

        this.setSmoothScroll()

        this.setScrollTrigger()
    }


    setupASScroll() {
        // https://github.com/ashthornton/asscroll
        const asscroll = new ASScroll({
            // ease: 0.5,
            disableRaf: true,
        });

        gsap.ticker.add(asscroll.update);

        ScrollTrigger.defaults({
            scroller: asscroll.containerElement,
        });

        ScrollTrigger.scrollerProxy(asscroll.containerElement, {
            scrollTop(value) {
                if (arguments.length) {
                    asscroll.currentPos = value;
                    return;
                }
                return asscroll.currentPos;
            },
            getBoundingClientRect() {
                return {
                    top: 0,
                    left: 0,
                    width: window.innerWidth,
                    height: window.innerHeight,
                };
            },
            fixedMarkers: true,
        });

        asscroll.on("update", ScrollTrigger.update);
        ScrollTrigger.addEventListener("refresh", asscroll.resize);

        requestAnimationFrame(() => {
            asscroll.enable({
                newScrollElements: document.querySelectorAll(
                    ".gsap-marker-start, .gsap-marker-end, [asscroll]"
                ),
            });
        });
        return asscroll;
    }

    setSmoothScroll() {
        this.asscroll = this.setupASScroll();
    }

    /**
     * @description Defines the path to follow for the scrolling animation
     */
    setScrollTrigger() {

        //----------

        ScrollTrigger.matchMedia({

            // ----- DESKTOP TIMELINES -----
            "(min-width: 1072px)": () => {

                // FIRST SECTION ------------------------------------
                // Move straight to pass the room window
                this.initialMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".initial-move",
                        start: "center center",
                        end: "bottom 5%",
                        scrub: 1,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true,
                    }
                })

                this.initialMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.initialMove.desktop.position.x,
                    y: cameraMovement.initialMove.desktop.position.y,
                    z: cameraMovement.initialMove.desktop.position.z,
                })


                // FIRST SECTION ------------------------------------
                // Focus on the brain
                this.firstMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".first-move",
                        start: "center center",
                        end: "bottom top",
                        scrub: 2,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true
                    }
                })

                // Move and rotate camera
                this.firstMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.firstMove.desktop.position.x,
                    y: cameraMovement.firstMove.desktop.position.y,
                    z: cameraMovement.firstMove.desktop.position.z,
                },
                    "brainFocus"
                ).to(this.camera.instance.rotation, {
                    x: cameraMovement.firstMove.desktop.rotation.x,
                    y: cameraMovement.firstMove.desktop.rotation.y,
                    z: cameraMovement.firstMove.desktop.rotation.z,
                },
                    "brainFocus"
                )


                // SECOND SECTION ------------------------------------
                // Focus on the cameras
                this.secondMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".second-move",
                        start: "center center",
                        end: "bottom 40%",
                        scrub: 2,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true
                    }
                })

                // Move and rotate camera
                this.secondMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.secondMove.desktop.position.x,
                    y: cameraMovement.secondMove.desktop.position.y,
                    z: cameraMovement.secondMove.desktop.position.z,
                },
                    "cameraFocus"
                ).to(this.camera.instance.rotation, {
                    x: cameraMovement.secondMove.desktop.rotation.x,
                    y: cameraMovement.secondMove.desktop.rotation.y,
                    z: cameraMovement.secondMove.desktop.rotation.z,
                },
                    "cameraFocus"
                )

                // THIRD SECTION ------------------------------------
                // Go to the corner back of the surgery room
                this.thirdMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".third-move",
                        start: "center center",
                        end: "bottom 40%",
                        scrub: 2,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true
                    }
                })

                // Move and rotate camera
                this.thirdMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.thirdMove.desktop.position.x,
                    y: cameraMovement.thirdMove.desktop.position.y,
                    z: cameraMovement.thirdMove.desktop.position.z,
                },
                    "roomCorner"
                ).to(this.camera.instance.rotation, {
                    x: cameraMovement.thirdMove.desktop.rotation.x,
                    y: cameraMovement.thirdMove.desktop.rotation.y,
                    z: cameraMovement.thirdMove.desktop.rotation.z,
                },
                    "roomCorner"
                )

                // FOURTH SECTION ------------------------------------
                // Look at the computer screens of the prototype
                this.fourthMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".fourth-move",
                        start: "center center",
                        end: "bottom 40%",
                        scrub: 2,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true
                    }
                })

                // Move and rotate camera
                this.fourthMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.fourthMove.desktop.position.x,
                    y: cameraMovement.fourthMove.desktop.position.y,
                    z: cameraMovement.fourthMove.desktop.position.z,
                },
                    "computerScreens"
                ).to(this.camera.instance.rotation, {
                    x: cameraMovement.fourthMove.desktop.rotation.x,
                    y: cameraMovement.fourthMove.desktop.rotation.y,
                    z: cameraMovement.fourthMove.desktop.rotation.z,
                },
                    "computerScreens"
                )


                // FINAL MOVE ------------------------------------
                // Get closer to the computer screens of the prototype
                this.fourthMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".fifth-move",
                        start: "center center",
                        end: "bottom 40%",
                        scrub: 2,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true
                    }
                })

                // Move and rotate camera
                this.fourthMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.fifthMove.desktop.position.x,
                    y: cameraMovement.fifthMove.desktop.position.y,
                    z: cameraMovement.fifthMove.desktop.position.z,
                },
                    "closeToScreens"
                )

            },

            // ----- MOBILE TIMELINES -----
            // TODO: GET THE COORDINATES FOR EACH MOVEMENT!
            "(max-width: 1072px)": () => {
                // FIRST SECTION ------------------------------------
                // Move straight to pass the room window
                this.initialMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".initial-move",
                        start: "center center",
                        end: "bottom 5%",
                        scrub: 1,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true,
                    }
                })

                this.initialMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.initialMove.mobile.position.x,
                    y: cameraMovement.initialMove.mobile.position.y,
                    z: cameraMovement.initialMove.mobile.position.z,
                })


                // FIRST SECTION ------------------------------------
                // Focus on the brain
                this.firstMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".first-move",
                        start: "center center",
                        end: "bottom top",
                        scrub: 2,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true
                    }
                })

                // Move and rotate camera
                this.firstMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.firstMove.mobile.position.x,
                    y: cameraMovement.firstMove.mobile.position.y,
                    z: cameraMovement.firstMove.mobile.position.z,
                },
                    "brainFocus"
                ).to(this.camera.instance.rotation, {
                    x: cameraMovement.firstMove.mobile.rotation.x,
                    y: cameraMovement.firstMove.mobile.rotation.y,
                    z: cameraMovement.firstMove.mobile.rotation.z,
                },
                    "brainFocus"
                )


                // SECOND SECTION ------------------------------------
                // Focus on the cameras
                this.secondMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".second-move",
                        start: "center center",
                        end: "bottom 40%",
                        scrub: 2,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true
                    }
                })

                // Move and rotate camera
                this.secondMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.secondMove.mobile.position.x,
                    y: cameraMovement.secondMove.mobile.position.y,
                    z: cameraMovement.secondMove.mobile.position.z,
                },
                    "cameraFocus"
                ).to(this.camera.instance.rotation, {
                    x: cameraMovement.secondMove.mobile.rotation.x,
                    y: cameraMovement.secondMove.mobile.rotation.y,
                    z: cameraMovement.secondMove.mobile.rotation.z,
                },
                    "cameraFocus"
                )

                // THIRD SECTION ------------------------------------
                // Go to the corner back of the surgery room
                this.thirdMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".third-move",
                        start: "center center",
                        end: "bottom 40%",
                        scrub: 2,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true
                    }
                })

                // Move and rotate camera
                this.thirdMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.thirdMove.mobile.position.x,
                    y: cameraMovement.thirdMove.mobile.position.y,
                    z: cameraMovement.thirdMove.mobile.position.z,
                },
                    "roomCorner"
                ).to(this.camera.instance.rotation, {
                    x: cameraMovement.thirdMove.mobile.rotation.x,
                    y: cameraMovement.thirdMove.mobile.rotation.y,
                    z: cameraMovement.thirdMove.mobile.rotation.z,
                },
                    "roomCorner"
                )

                // FOURTH SECTION ------------------------------------
                // Look at the computer screens of the prototype
                this.fourthMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".fourth-move",
                        start: "center center",
                        end: "bottom 40%",
                        scrub: 2,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true
                    }
                })

                // Move and rotate camera
                this.fourthMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.fourthMove.mobile.position.x,
                    y: cameraMovement.fourthMove.mobile.position.y,
                    z: cameraMovement.fourthMove.mobile.position.z,
                },
                    "computerScreens"
                ).to(this.camera.instance.rotation, {
                    x: cameraMovement.fourthMove.mobile.rotation.x,
                    y: cameraMovement.fourthMove.mobile.rotation.y,
                    z: cameraMovement.fourthMove.mobile.rotation.z,
                },
                    "computerScreens"
                )

                // FINAL MOVE ------------------------------------
                // Get closer to the computer screens of the prototype
                this.fourthMoveTimeline = new gsap.timeline({
                    scrollTrigger: {
                        trigger: ".fifth-move",
                        start: "center center",
                        end: "bottom 40%",
                        scrub: 2,
                        invalidateOnRefresh: true,
                        fastScrollEnd: true,
                        preventOverlaps: true
                    }
                })

                // Move and rotate camera
                this.fourthMoveTimeline.to(this.camera.instance.position, {
                    x: cameraMovement.fifthMove.mobile.position.x,
                    y: cameraMovement.fifthMove.mobile.position.y,
                    z: cameraMovement.fifthMove.mobile.position.z,
                },
                    "closeToScreens"
                )

            },

            // Animations to be done on all sizes to be the same!
            all: function () { },
        })

    }

}